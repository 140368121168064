import React from 'react';
import './TicketList.scss';
import TicketItem from "./TicketItem";

class TicketList extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        let tickets = this.props.tickets.map((ticket, step)=>{
            return <TicketItem info={ticket} key={ticket.id} hideAdminActions={ this.props.user.userType!=="admin" } hideEditAction={ this.props.user.userType!=="admin" && ticket.resolved }/>;
        });

        return <div>
            {tickets}
        </div>
    }

}

export default TicketList;