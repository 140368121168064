import React from 'react';
import './TicketListPage.scss';
import HeaderCommon from "../common/HeaderCommon";
import TicketList from "../common/TicketList";
import {NavLink} from "react-router-dom";

class TicketListPage extends React.Component {

    constructor(props){
        super(props);

        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.searchFilter = this.searchFilter.bind(this);
        this.ownerFilter = this.ownerFilter.bind(this);
        this.downloadResolvedReport = this.downloadResolvedReport.bind(this)
        this.downloadPaidReport = this.downloadPaidReport.bind(this);

        this.resolvedTicketsForReport = null;
        this.paidTicketsForReport = null

        this.state = {
            searchText:""
        };
    }

    sortByDateDesc( a, b ) {
        if ( a.date < b.date ){
            return 1;
        }
        if ( a.date > b.date ){
            return -1;
        }
        return 0;
    }

    ownerFilter(ticket){
        return (
            ticket.owner.id === this.props.user.id
        );
    }

    searchFilter(ticket){
        return (
            ticket.projectName.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
            ticket.client.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
            ticket.supplierName.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
            ticket.folio.toString().toLowerCase().includes(this.state.searchText.toLowerCase())
        );
    }

    handleSearchInputChange(event){
        this.setState({"searchText": event.target.value});
    }

    downloadResolvedReport(){
        let json = this.resolvedTicketsForReport;

        let fields = ["folio", "client", "projectName", "amount", "currency", "supplierName"];
        let replacer = function(key, value) { return value === null ? '' : value }
        let csv = json.map(function(row){
            return fields.map(function(fieldName){
                return JSON.stringify(row[fieldName], replacer)
            }).join(',')
        })
        csv.unshift(fields.join(','));
        csv = csv.join('\r\n');

        let blob = new Blob(["\ufeff", csv], { type: 'text/csv;' });
        window.location.href = URL.createObjectURL(blob);
    }

    downloadPaidReport(){
        let json = this.paidTicketsForReport;

        let fields = ["folio", "client", "projectName", "amount", "currency", "supplierName"];
        let replacer = function(key, value) { return value === null ? '' : value }
        let csv = json.map(function(row){
            return fields.map(function(fieldName){
                return JSON.stringify(row[fieldName], replacer)
            }).join(',')
        })
        csv.unshift(fields.join(','));
        csv = csv.join('\r\n');

        let blob = new Blob(["\ufeff", csv], { type: 'text/csv;' });
        window.location.href = URL.createObjectURL(blob);
    }

    render() {

        let resolvedTickets = [];
        let unresolvedTickets = [];
        let paidTickets = [];

        for(let ticket in this.props.tickets){
            if(this.props.tickets.hasOwnProperty(ticket) && "id" in this.props.tickets[ticket]){
                if(this.props.tickets[ticket].resolved && this.props.tickets[ticket].paid){
                    paidTickets.push(this.props.tickets[ticket]);
                }else if(this.props.tickets[ticket].resolved){
                    resolvedTickets.push(this.props.tickets[ticket]);
                }else{
                    unresolvedTickets.push(this.props.tickets[ticket]);
                }
            }
        }

        resolvedTickets.sort(this.sortByDateDesc);
        unresolvedTickets.sort(this.sortByDateDesc);
        paidTickets.sort(this.sortByDateDesc);

        //filter
        unresolvedTickets = unresolvedTickets.filter(this.searchFilter);
        resolvedTickets = resolvedTickets.filter(this.searchFilter);
        paidTickets = paidTickets.filter(this.searchFilter);

        if(this.props.user.userType!=="admin"){
            unresolvedTickets = unresolvedTickets.filter(this.ownerFilter);
            resolvedTickets = resolvedTickets.filter(this.ownerFilter);
            paidTickets = paidTickets.filter(this.ownerFilter);
        }

        //asigno this.resolvedTicketsForReport para reporte
        this.resolvedTicketsForReport = resolvedTickets;
        //asigno this.paidTicketsForReport para reporte
        this.paidTicketsForReport = paidTickets;

        return <div className={"pageMainContainer"}>
            <HeaderCommon user={this.props.user} firebaseAuth={this.props.firebaseAuth}/>
            <h1>Solicitudes de pago</h1>
            <div className={"searchBar"}>
                <input type={"text"} placeholder={"Buscar solicitud"} value={this.state.searchText} onChange={this.handleSearchInputChange}/>
            </div>
            <div className={"titleCreateWrp"}>
                <h2>Pendientes</h2>
                <NavLink className={"button"} to="/create">Crear solicitud</NavLink>
            </div>
            { (unresolvedTickets.length>0)?
                <TicketList user={this.props.user} tickets={unresolvedTickets}/>
                : (this.state.searchText!=="")? <p className={"noTicketsMsg"}>No hay ninguna solicitud que coincida con tu búsqueda.</p> : <p className={"noTicketsMsg"}>No hay solicitudes pendientes.</p>
            }
            <hr />
            <div className={"titleCreateWrp"}>
                <h2>Atendidas</h2>
                {(resolvedTickets.length>0)? <button className={"button"} onClick={this.downloadResolvedReport}>Descargar reporte</button>:""}
            </div>
            { (resolvedTickets.length>0)?
                <div><TicketList user={this.props.user} tickets={resolvedTickets} /></div>
                : (this.state.searchText!=="")? <p className={"noTicketsMsg"}>No hay ninguna solicitud que coincida con tu búsqueda.</p> : <p className={"noTicketsMsg"}>No hay solicitudes atendidas.</p>
            }
            <hr />
            <div className={"titleCreateWrp"}>
                <h2>Pagadas</h2>
                {(paidTickets.length>0)? <button className={"button"} onClick={this.downloadPaidReport}>Descargar reporte</button>:""}
            </div>
            { (paidTickets.length>0)?
                <TicketList user={this.props.user} tickets={paidTickets} />
                : (this.state.searchText!=="")? <p className={"noTicketsMsg"}>No hay ninguna solicitud que coincida con tu búsqueda.</p> : <p className={"noTicketsMsg"}>No hay solicitudes pagadas.</p>
            }
        </div>
    }

}

export default TicketListPage;