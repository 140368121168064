import React from "react";
import './RegisterPage.scss';
import {child, getDatabase, ref, get} from "firebase/database";
import {createUserWithEmailAndPassword} from "firebase/auth";
import HeaderCommon from "../common/HeaderCommon";

class RegisterPage extends React.Component{

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

		this.database = getDatabase();

		this.state = {
			email:"",
			password:"",
			repeatPassword:""
		};
	}

	handleInputChange(event){
		this.setState({[event.target.name]: event.target.value});
	}

	handleSubmit(event){
		event.preventDefault();

		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

		if(!emailRegex.test(this.state.email)){
			alert("El correo electrónico es inválido.");
		}else if(!passwordRegex.test(this.state.password)){
			alert("La contraseña es inválida. Asegúrate de usar una contraseña de al menos 6 caracteres, que contenga al menos un número y al menos una letra.");
		}else if(this.state.password!==this.state.repeatPassword){
			alert("Las contraseñas no coinciden.");
		}else{

			get(child(ref(this.database), "userInvitations/"))
				.then( snapshot => {
					const notAdmin = snapshot.val()["admins"].indexOf(this.state.email)===-1;
					let notCollab;

					if("collabs" in snapshot.val()){
						notCollab = snapshot.val()["collabs"].indexOf(this.state.email)===-1;
					}

					if (!snapshot.exists() || (notAdmin && notCollab)) {
						return Promise.reject({code:"sin-invitacion"});
					} else{
						return createUserWithEmailAndPassword(this.props.firebaseAuth, this.state.email, this.state.password);
					}
				})
				.catch(error=>{
					if (error.code === "auth/email-already-in-use") {
						alert(`El correo ${this.state.email} ya está registrado.`);
					}else if(error.code === "sin-invitacion"){
						alert("Tu correo no tiene permisos de acceso, solicita una invitación al administrador.");
					} else{
						alert("Hubo un error registrando el usuario, contacte al administrador.");
						console.log(error);
					}
				});

		}
	}

	render(){
		return <div className={"pageMainContainer"}>
			<HeaderCommon/>
			<h1>Registro</h1>
			<div className={"pageContent"}>
				<form onSubmit={this.handleSubmit}>
					<input type={"email"} value={this.state.email} name={"email"} placeholder={"Correo electrónico"} onChange={this.handleInputChange}/>
					<input type={"password"} value={this.state.password} name={"password"} placeholder={"Contraseña"} onChange={this.handleInputChange}/>
					<input type={"password"} value={this.state.repeatPassword} name={"repeatPassword"} placeholder={"Repetir contraseña"} onChange={this.handleInputChange}/>
					<button type={"submit"} className={"button"}>Registrate</button>
				</form>
			</div>
		</div>
	}
}

export default RegisterPage;