import React from 'react';
import { NavLink } from "react-router-dom";
import "./NavigationMenu.scss"

class NavigationMenu extends React.Component{
    
    constructor(props) {
        super(props);
        
        this.toggleNav = this.toggleNav.bind(this);
        
        this.state = {
            navOpened:false
        };
    }

    toggleNav(){
        this.setState({ navOpened:!this.state.navOpened})
    }
    
    render(){
        return(
            <nav className={this.state.navOpened? "navigationMenu opened": "navigationMenu"}>
                <button type={"button"} onClick={this.toggleNav} />
                <ul>
                    <li><NavLink to="/" end>Solicitudes</NavLink></li>
                    {this.props.user.userType==="admin"?
                        <li><NavLink to="/users">Usuarios</NavLink></li>
                    :""}
                    <li><NavLink to="/notifications">Notificaciones</NavLink></li>
                    <li><a onClick={this.props.logout}>Cerrar sesión</a></li>
                </ul>
            </nav>
        );
    }
}

export default NavigationMenu;