import React from 'react';
import './HeaderCommon.scss';
import {imagesFolder} from "../../helpers/imagesFolder";
import {signOut} from "firebase/auth";
import NavigationMenu from "./NavigationMenu";

class HeaderCommon extends React.Component {

    constructor(props){
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        signOut(this.props.firebaseAuth)
            .catch((error)=>{
                alert(error);
            });
    }

    render() {
        return <div className={"mainHeader"}>
            {this.props.user? <NavigationMenu logout={this.handleLogout} user={this.props.user} /> : ""}
            <img className={"mainHeaderLogo"} src={imagesFolder(`./pbl.png`)} alt={"logo PBL"} />
        </div>
    }

}

export default HeaderCommon;