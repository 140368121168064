import React from 'react';
import './TicketItem.scss';
import {getDatabase, onValue, push, ref, remove, update} from "firebase/database";

const baseURL = document.querySelector('base')?.getAttribute('href') ?? '/';

class TicketItem extends React.Component {
    constructor(props){
        super(props);

        this.openTicket = this.openTicket.bind(this);
        this.closeTicket = this.closeTicket.bind(this);
        this.handleRejectClick = this.handleRejectClick.bind(this);
        this.handleAuthorizeClick = this.handleAuthorizeClick.bind(this);
        this.handleEditTicketClick = this.handleEditTicketClick.bind(this);
        this.handleMarkAsPaid = this.handleMarkAsPaid.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);

        this.state = {
            opened:false,
            extraInfoRequested:false,
            extraInfo: {
                area:"",
                concept:"",
                deductibleExpense:"",
                description:"",
                paymentMethod:"",
                attachedFiles:[]
            }
        };
    }

    handleMarkAsPaid(event){

        event.stopPropagation();

        update(ref(getDatabase(), "tickets/" + this.props.info.id), { paid:true })
            .catch(error=>{
                console.log(error);
                alert("Hubo un problema guardando la solicitud, vuelva a intentarlo.");
            });
    }

    handleEditTicketClick(event){
        event.stopPropagation();
        window.location.href = baseURL + "/#/edit/" + this.props.info.id;
    }

    handleHeaderClick(event){
        event.stopPropagation();

        if(this.state.opened){
            this.closeTicket();
        }else{
            this.openTicket();
        }
    }

    openTicket(){
        this.setState({opened:!this.state.opened});

        if (!this.state.extraInfoRequested) {
            this.requestExtraInfo();
        }

        if(this.props.info.unread){
            this.markAsRead();
        }
    }

    closeTicket(){
        this.setState({opened:false});
    }

    handleAuthorizeClick(event){

        //console.log("handleAuthorizeClick");

        event.stopPropagation();

        update(ref(getDatabase(), "tickets/" + this.props.info.id), { resolved:true, approved:true })
            .then(_=>{
                const notificationTitle = this.props.info.client + " / " + this.props.info.projectName;
                this.createNotification(notificationTitle, "aprobada");
            })
            .catch(error=>{
                console.log(error);
                alert("Hubo un problema guardando la solicitud, vuelva a intentarlo.");
            });
    }

    handleRejectClick(event){

        //console.log("handleRejectClick");

        event.stopPropagation();

        update(ref(getDatabase(), "tickets/" + this.props.info.id), { resolved:true, approved:false })
            .then(_=>{
                const notificationTitle = this.props.info.client + " / " + this.props.info.projectName;
                this.createNotification(notificationTitle, "rechazada");
            })
            .catch(error=>{
                console.log(error);
                alert("Hubo un problema guardando la solicitud, vuelva a intentarlo.");
            });
    }

    createNotification(ticketTitle, operation) {
        let notificationInfo = {
            "body": "La solicitud " + ticketTitle + " fue " + operation + ".",
            "id": "",
            "read": false,
            "title": "Solicitud " + operation,
            "date": Date.now()
        }

        push(ref(getDatabase(), "notifications/" + this.props.info.owner.id), notificationInfo)
            .then(dbReference=>{
                return update(dbReference, {id:dbReference.key});
            })
            .catch(error=>{
                console.log(error);
            })
    }

    markAsRead(){
        update(ref(getDatabase(), "tickets/" + this.props.info.id), { unread:false })
            .catch(error=>{
                console.log(error);
            });
    }

    requestExtraInfo(){
        const dbRef = ref(getDatabase(), "ticketDetails/" + this.props.info.id);
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            this.setState({extraInfo:data, extraInfoRequested:true});
        });
    }

    render() {
        let openedClass = (this.state.opened)? " ticketItem-opened": "";
        let unreadClass = (this.props.info.unread)? " ticketItem-unread": "";
        let approvedClass = (this.props.info.approved===true)? " ticketItem-approved": "";
        let rejectedClass = (this.props.info.approved===false)? " ticketItem-rejected": "";
        let importantClass = (this.props.info.important && !this.props.info.resolved)? " ticketItem-important": "";

        let attachedFiles = [];

        if(this.state.extraInfo && this.state.extraInfo.attachedFiles){
            attachedFiles = this.state.extraInfo.attachedFiles.map((file, step)=>{
                return <li key={file.id}>
                    <a href={file.url} target={"_blank"} rel="noreferrer" ><span className={"fileName"}>{ file.name }</span></a>
                </li>
            })
        }

        let dateToShow = new Date(this.props.info.date).toLocaleString("es-MX", { year: 'numeric', month: 'numeric', day: 'numeric', hour:"numeric",minute:"numeric" });
        let extraInformation = <div className={"ticketExtraWrp"}>
            <p className={"ticketConcept"}>{ this.state.extraInfo.concept }</p>
            <p>{this.state.extraInfo.description}</p>
            <ul>
                <li><span>Nombre del proveedor:</span> {this.props.info.supplierName}</li>
                <li><span>Forma de pago:</span> {this.state.extraInfo.paymentMethod}</li>
                <li><span>Tipo de pago:</span> {this.state.extraInfo.deductibleExpense}</li>
                <li><span>Banco:</span> {this.state.extraInfo.bankName}</li>
                <li><span>Nombre beneficiario cuenta:</span> {this.state.extraInfo.bankAccountOwner}</li>
                <li><span>Número de cuenta:</span> {this.state.extraInfo.bankAccountNumber}</li>
                <li><span>Área:</span> {this.state.extraInfo.area}</li>
                <li><span>Solicitado por:</span> {this.props.info.owner.name}</li>
            </ul>

            {this.state.extraInfo.attachedFiles ?
                <ul className={"attachedFilesWrp"}>
                    {attachedFiles}
                </ul> : ""
            }
            <div className={"ticketBtnsWrp"}>
                <div>
                    {!this.props.hideEditAction?
                        <button className={"button buttonSecondary"} type={"button"} onClick={this.handleEditTicketClick}>Editar</button>
                    : ""}
                </div>
                {!this.props.hideAdminActions?
                    <div>
                        {!this.props.info.resolved? <button className={"button"} type={"button"} onClick={this.handleRejectClick}>Rechazar</button> : ""}
                        {!this.props.info.resolved? <button className={"button"} type={"button"} onClick={this.handleAuthorizeClick}>Autorizar</button> : ""}
                        {this.props.info.approved && !this.props.info.paid? <button className={"button"} type={"button"} onClick={this.handleMarkAsPaid}>Marcar como pagada</button> : ""}
                    </div>
                : ""}
            </div>
        </div>
        const numberFormat = new Intl.NumberFormat('es-MX', {style: 'currency', currency: this.props.info.currency});
        const amountString = numberFormat.format(this.props.info.amount);

        return <div className={"ticketItem" + openedClass + approvedClass + rejectedClass + importantClass + unreadClass} >
            <div className={"ticketHeader"} onClick={this.handleHeaderClick}>
                <span className={"ticketSupWrp"}>
                    <span className={"ticketFolio"}>Folio: {this.props.info.folio}</span>
                    <span>{dateToShow}</span>
                </span>
                <div className={"ticketInfWrp"}>
                    <span>{this.props.info.client} / {this.props.info.projectName}</span>
                    <span className={"ticketAmount"}>{amountString}</span>
                </div>
            </div>
            { (this.state.opened)? extraInformation: "" }
        </div>
    }
}

export default TicketItem;