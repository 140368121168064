import React from 'react';
import './LoginPage.scss';
import HeaderCommon from "../common/HeaderCommon";
import {signInWithEmailAndPassword} from "firebase/auth";
import {NavLink} from "react-router-dom";
import {deleteToken} from "firebase/messaging";

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;

class LoginPage extends React.Component {

    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleSignInWithEmailAndPassError = this.handleSignInWithEmailAndPassError.bind(this);

        this.state = {
            email:"",
            password:""
        };
    }

    handleFormSubmit(event){
        event.preventDefault();

        if(!emailRegex.test(this.state.email)){
            alert("El correo electrónico es inválido.");
        }else if(!passwordRegex.test(this.state.password)){
            alert("Correo electrónico o contraseña inválidos.");
        }else{
            signInWithEmailAndPassword(this.props.firebaseAuth, this.state.email, this.state.password)
                .catch(this.handleSignInWithEmailAndPassError)
        }
    }

    handleSignInWithEmailAndPassError(error){
        switch (error.code) {
            case "auth/user-not-found":
                alert("No existe una cuenta registrada con el correo electrónico proporcionado.");
                break;
            case "auth/wrong-password":
                alert("Usuario o contraseña incorrectos.");
                break;
            default:
                alert("Hubo un problema inesperado, contacta al administrador del sistema.");
                console.log(error);
                break;
        }
    }

    handleInputChange(event){
        this.setState({[event.target.name]: event.target.value});
    }

    render() {

        return (
            <div className={"pageMainContainer"}>
                <HeaderCommon/>
                <h1>Ingresa</h1>
                <div className={"pageContent"}>
                    <form onSubmit={this.handleFormSubmit}>
                        <input type={"email"} value={this.state.email} name="email" placeholder={"Correo electrónico"} onChange={this.handleInputChange}/>
                        <input type={"password"} value={this.state.password} name="password" placeholder={"Contraseña"} onChange={this.handleInputChange}/>
                        <button type={"submit"} className={"button"}>Ingresar</button>
                    </form>
                    <p className={"small"}>Si no recuerdas tu contraseña, puedes recuperarla <NavLink className={"registerLink"} to="/passwordRecovery">haciendo clic aquí.</NavLink></p>
                    <p className={"small"}>Puedes registrarte <NavLink className={"registerLink"} to="/register">haciendo clic aquí.</NavLink></p>
                </div>
            </div>
        );
    }
}

export default LoginPage;