import React from 'react';
import "./NotificationsPage.scss";
import HeaderCommon from "../common/HeaderCommon";
import Notification from "../common/Notification";
import {getDatabase,onValue,ref} from "firebase/database";

class NotificationsPage extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            notifications:null
        };
    }

    componentDidMount(){
        this.startFirebaseConnection();
    }

    startFirebaseConnection(){
        onValue(ref(getDatabase(), "notifications/" + this.props.user.id), (snapshot)=>{
            if(snapshot.exists()){
                this.setState({ notifications:snapshot.val()})
            }
        });
    }

    sortByDateDesc( a, b ) {
        if ( a.date < b.date ){
            return 1;
        }
        if ( a.date > b.date ){
            return -1;
        }
        return 0;
    }

    render(){

        let notifications = [];

        for(let notification in this.state.notifications){
            if(this.state.notifications.hasOwnProperty(notification)){
                notifications.push(this.state.notifications[notification]);
            }
        }

        notifications.sort(this.sortByDateDesc);

        notifications = notifications.map((notification, step)=>{
            return <Notification title={notification.title} body={notification.body} key={notification.id}/>
        });

        return <div className={"pageMainContainer"}>
            <HeaderCommon user={this.props.user} firebaseAuth={this.props.firebaseAuth} />
            <h1>Notificaciones</h1>
            <div className={"pageContent"}>

                { (notifications.length>0)?
                    <ul className={"notificationsList"}>{notifications}</ul>
                    : <p className={""}>No hay notificaciones.</p>
                }

            </div>
        </div>
    }
}

export default NotificationsPage;