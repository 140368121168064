import React from "react";
import {sendPasswordResetEmail} from "firebase/auth";
import "./PasswordRecovery.scss";
import HeaderCommon from "../common/HeaderCommon";
import {NavLink} from "react-router-dom";

class PasswordRecovery extends React.Component{
	constructor(props) {
		super(props);

		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);

		this.state = {
			email:""
		};
	}

	handleEmailChange(event){
		this.setState({ email:event.target.value })
	}

	handleFormSubmit(event){
		event.preventDefault();

		const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if(!emailRegex.test(this.state.email)){
			alert("El correo electrónico es inválido.");
		}else{
			sendPasswordResetEmail(this.props.firebaseAuth, this.state.email)
				.then(_=>{
					alert("Enviamos a tu correo las instrucciones para reestablecer tu contraseña.");
				})
				.catch((error) => {
					if(error.code==="auth/user-not-found"){
						alert("El correo electrónico que proporcionaste no está registrado.");
					}else{
						alert(error);
					}
				});
		}
	}

	render(){
		return <div className={"pageMainContainer"}>
			<HeaderCommon/>
			<h1>Recupera tu contraseña</h1>
			<div className={"pageContent"}>
				<p>Ingresa el correo electrónico con el que creaste la cuenta y recibirás un correo con instrucciones para recuperar tu contraseña.</p>
				<form onSubmit={this.handleFormSubmit}>
					<input type={"email"} value={this.state.email} placeholder={"Correo electrónico"} name={"email"} onChange={this.handleEmailChange}/>
					<button type={"submit"} className={"button"}>Enviar</button>
				</form>
			</div>
		</div>
	}
}

export default PasswordRecovery;