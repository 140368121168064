import React, {Component} from "react";
import "./Notification.scss";
import {NavLink} from "react-router-dom";

class Notification extends Component{
    render(){
        return <li className={"notificationsItem"}>
            <NavLink to={"/"}>
                <h2>{this.props.title}</h2>
                <p>{this.props.body}</p>
            </NavLink>
        </li>
    }
}

export default Notification;