import React, {Component} from "react";
import HeaderCommon from "../common/HeaderCommon";
import {getDatabase, ref, runTransaction, get} from "firebase/database";

class UsersPage extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            name: "",
            email: "",
            userType: ""
        };
    }

    handleSubmit(event) {
        event.preventDefault();

        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.userType === "") {
            alert("Selecciona el tipo de usuario.");
        } else if (this.state.name === "") {
            alert("El nombre es inválido.");
        } else if (!emailRegex.test(this.state.email)) {
            alert("El correo electrónico es inválido.");
        } else {

            runTransaction(ref(getDatabase(), "userInvitations/"), (userInvitations) => {
                if(userInvitations){
                    if(!userInvitations[this.state.userType + "s"]){
                        userInvitations[this.state.userType + "s"] = [];
                    }
                    userInvitations[this.state.userType + "s"].push(this.state.email);
                    userInvitations["names"][this.state.email.replace(/\./g, "").replace("@", "")] = this.state.name;
                }
                return userInvitations;
            })
            .then(data=>{
                if(data.committed){
                    alert("La invitación se registró correctamente.");
                }else{
                    alert("Hubo un problema generando la invitación. Contacte al administrador.");
                }
            })
            .catch(error=>{
                console.log(error);
                alert("Hubo un problema generando la invitación.");
            })
        }

    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        return <div className={"pageMainContainer"}>
            <HeaderCommon user={this.props.user} firebaseAuth={this.props.firebaseAuth}/>
            <h1>Usuarios</h1>
            <div className={"pageContent"}>
                <p>Crear una invitación de usuario:</p>
                <form onSubmit={this.handleSubmit}>
                    <select name={"userType"} value={this.state.userType} onChange={this.handleInputChange}>
                        <option value={""}>Tipo de usuario</option>
                        <option value={"admin"}>Administrador</option>
                        <option value={"collab"}>Colaborador</option>
                    </select>
                    <input type={"text"} value={this.state.name} name={"name"} placeholder={"Nombre"}
                           onChange={this.handleInputChange}/>
                    <input type={"email"} value={this.state.email} name={"email"} placeholder={"Correo electrónico"}
                           onChange={this.handleInputChange}/>
                    <button type={"submit"} className={"button"}>Crear invitación</button>
                </form>
            </div>
        </div>
    }
}

export default UsersPage;