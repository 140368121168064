import React from 'react';
import './CreateTicketPage.scss';
import HeaderCommon from "../common/HeaderCommon";
import {ref, push, getDatabase, runTransaction, onValue, query, equalTo, orderByChild, update} from "firebase/database";
import {getStorage, uploadBytes, deleteObject, getDownloadURL} from "firebase/storage";
import {ref as storageRef} from "firebase/storage";
import {NavLink} from "react-router-dom";
import {generatePrefix} from "../../helpers/generatePrefix";

const baseURL = document.querySelector('base')?.getAttribute('href') ?? '/';

class CreateTicketPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFileAttachment = this.handleFileAttachment.bind(this);
        this.startFirebaseUsersListConnection = this.startFirebaseUsersListConnection.bind(this);

        this.randomSessionPrefix = generatePrefix();
        this.adminIds = [];

        this.state = {
            projectName: "",
            amount: "",
            supplierName: "",
            concept: "",
            description: "",
            area: "",
            client: "",
            paymentMethod: "",
            deductibleExpense: "",
            important: "0",
            currency: "MXN",
            bankName: "",
            bankAccountOwner: "",
            bankAccountNumber: "",
            attachedFiles: []
        };

        this.attachmentStorageReferences = {};
    }

    handleFileAttachment(event) {
        const fileId = this.randomSessionPrefix + "_" + Date.now();
        const fileReference = storageRef(getStorage(), 'uploadedFiles/' + fileId);

        uploadBytes(fileReference, event.target.files[0])
            .then((snapshot) => {
                getDownloadURL(fileReference).then(downloadURL => {
                    if (event.target.files[0]) {
                        const fileName = event.target.files[0].name;
                        let attachedFiles = JSON.parse(JSON.stringify(this.state.attachedFiles));
                        attachedFiles.push(
                            {name: fileName, id: fileId, url: downloadURL}
                        );
                        this.setState({
                            attachedFiles: attachedFiles
                        });
                        this.attachmentStorageReferences[fileId] = fileReference;
                    }
                });
            });
    }

    handleDetachFileBtnClick(id) {

        let attachedFiles = JSON.parse(JSON.stringify(this.state.attachedFiles));

        attachedFiles = attachedFiles.filter(function (obj) {
            return obj.id !== id;
        });

        this.setState({
            attachedFiles: attachedFiles
        });

        deleteObject(this.attachmentStorageReferences[id]).then(() => {
            //console.log("Borrado de storage");
        }).catch((error) => {
            console.log("Error en borrado de archivo de storage");
        });
    }

    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleFormSubmit(event) {
        event.preventDefault();

        if (this.state.area === "") {
            alert("Debes seleccionar un área.");
        } else if (this.state.client === "") {
            alert("Debes seleccionar un cliente.");
        } else if (this.state.projectName === "") {
            alert("Debes ingresar el nombre del proyecto.");
        } else if (this.state.supplierName === "") {
            alert("Debes ingresar el nombre del proveedor.");
        } else if (this.state.concept === "") {
            alert("Debes seleccionar el concepto de pago.");
        } else if (this.state.description === "") {
            alert("Debes ingresar la descripción del pago.");
        } else if (this.state.deductibleExpense === "") {
            alert("Debes seleccionar el tipo de pago.");
        } else if (this.state.paymentMethod === "") {
            alert("Debes seleccionar la forma de pago.");
        } else if (this.state.amount === "") {
            alert("Debes ingresar el monto de pago.");
        } else {
            this.saveTicket();
        }
    }

    saveTicket() {
        let basicInfo = {
            amount: this.state.amount,
            client: this.state.client,
            currency: this.state.currency,
            important: (this.state.important === "1"),
            projectName: this.state.projectName,
            supplierName: this.state.supplierName,
            owner: {
                id: this.props.user.id,
                name: this.props.user.name
            },
            date: Date.now(),
            unread: true,
            resolved: false,
            paid: false
        };

        let extraInfo = {
            area: this.state.area,
            concept: this.state.concept,
            deductibleExpense: this.state.deductibleExpense,
            description: this.state.description,
            paymentMethod: this.state.paymentMethod,
            attachedFiles: this.state.attachedFiles,
            bankName: this.state.bankName,
            bankAccountOwner: this.state.bankAccountOwner,
            bankAccountNumber: this.state.bankAccountNumber
        };

        push(ref(getDatabase(), 'tickets/'), basicInfo)
            .then((dbReference) => {
                return runTransaction(ref(getDatabase()), (database) => {
                    if (database) {
                        database.tickets[dbReference.key].folio = database.nextFolio;
                        database.tickets[dbReference.key].id = dbReference.key;

                        if(!("ticketDetails" in database)){
                            database.ticketDetails = {};
                        }

                        database.ticketDetails[dbReference.key] = extraInfo;
                        database.nextFolio++;
                    }
                    return database;
                })
            })
            .then(_ => {
                return this.createNotifications(basicInfo.client + " / " + basicInfo.projectName);
            })
            .then(_ => {
                window.location.href = baseURL + "/#";
            })
            .catch(error => {
                console.log(error);
                alert("Hubo un problema guardando la solicitud, vuelva a intentarlo.");
            });
    }

    componentDidMount() {
        this.startFirebaseUsersListConnection();
    }

    startFirebaseUsersListConnection() {
        const q = query(ref(getDatabase(), "users/"), orderByChild("userType"), equalTo("admin"));
        onValue(q, snapshot => {
            this.adminIds = [];
            if (snapshot.exists()) {
                const usersInfo = snapshot.val();
                for (let user in usersInfo) {
                    if (usersInfo.hasOwnProperty(user)) {
                        this.adminIds.push(user);
                    }
                }
            }
        });
    }

    createNotifications(ticketTitle) {
        let notificationInfo = {
            "body": "Hay una nueva solicitud: " + ticketTitle + ".",
            "id": "",
            "read": false,
            "title": "Nueva solicitud",
            "date": Date.now()
        }
        
        this.adminIds.forEach((userId, step)=>{
            push(ref(getDatabase(), "notifications/" + userId), notificationInfo)
                .then(dbReference=>{
                    return update(dbReference, {id:dbReference.key});
                })
                .catch(error=>{
                    console.log(error);
                })
        });
    }

    render() {

        const attachedFiles = this.state.attachedFiles.map((file, step) => {
            return <li key={file.id}>
                <span className={"fileName"}>{file.name}</span>
                <button className={"detachFileBtn"} type={"button"}
                        onClick={this.handleDetachFileBtnClick.bind(this, file.id)}>x
                </button>
            </li>
        });

        return <div className={"pageMainContainer"}>
            <HeaderCommon user={this.props.user} firebaseAuth={this.props.firebaseAuth}/>
            <h1>Crear solicitud</h1>
            <div className={"pageContent"}>
                <form onSubmit={this.handleFormSubmit}>
                    <select name={"area"} value={this.state.area} onChange={this.handleInputChange}>
                        <option value={""}>Área</option>
                        <option value={"PBL Teams"}>PBL Teams</option>
                        <option value={"PBL Promos"}>PBL Promos</option>
                        <option value={"PBL Plus"}>PBL Plus</option>
                    </select>
                    <select name={"client"} value={this.state.client} onChange={this.handleInputChange}>
                        <option value={""}>Cliente</option>
                        <option value={"Aeroman"}>Aeroman</option>
                        <option value={"Aon"}>Aon</option>
                        <option value={"Colgate"}>Colgate</option>
                        <option value={"Cream Finance"}>Cream Finance</option>
                        <option value={"Emsep - Hockchi"}>Emsep - Hockchi</option>
                        <option value={"Engie"}>Engie</option>
                        <option value={"Hays"}>Hays</option>
                        <option value={"Ingersoll Rand"}>Ingersoll Rand</option>
                        <option value={"Ingram"}>Ingram</option>
                        <option value={"Interprotección"}>Interprotección</option>
                        <option value={"Kantar"}>Kantar</option>
                        <option value={"MRO Holdings"}>MRO Holdings</option>
                        <option value={"Qualitas"}>Qualitas</option>
                        <option value={"TechOps"}>TechOps</option>
                        <option value={"Thermo King"}>Thermo King</option>
                        <option value={"Trane"}>Trane</option>
                        <option value={"Truper"}>Truper</option>
                        <option value={"Urrea"}>Urrea</option>
                        <option value={"Yalo"}>Yalo</option>
                        <option value={"PBL - Interno"}>PBL - Interno</option>
                        <option value={"Sabadell"}>Sabadell</option>
                        <option value={"Stendhal"}>Stendhal</option>
                        <option value={"Otro"}>Otro</option>
                    </select>
                    <input type={"text"} value={this.state.projectName} name={"projectName"}
                           placeholder={"Nombre del proyecto"} onChange={this.handleInputChange}/>
                    <input type={"text"} value={this.state.supplierName} name={"supplierName"}
                           placeholder={"Nombre del proveedor"} onChange={this.handleInputChange}/>
                    <select name={"concept"} value={this.state.concept} onChange={this.handleInputChange}>
                        <option value={""}>Concepto</option>
                        <option value={"Pago de servicios"}>Pago de servicios</option>
                        <option value={"Compra de productos"}>Compra de productos</option>
                    </select>
                    <input type={"text"} value={this.state.description} name={"description"}
                           placeholder={"Descripción del pago"} onChange={this.handleInputChange}/>
                    <select name={"deductibleExpense"} value={this.state.deductibleExpense}
                            onChange={this.handleInputChange}>
                        <option value={""}>Tipo de pago</option>
                        <option value={"deducible"}>Deducible (genera IVA)</option>
                        <option value={"no deducible"}>No deducible</option>
                    </select>
                    <select name={"paymentMethod"} value={this.state.paymentMethod} onChange={this.handleInputChange}>
                        <option value={""}>Forma de pago</option>
                        <option value={"Anticipo"}>Anticipo</option>
                        <option value={"Pago directo a proveedor"}>Pago directo a proveedor</option>
                        <option value={"Reembolso"}>Reembolso</option>
                    </select>
                    <div className={"radioOptionsInput"}>
                        <p>Moneda:</p>
                        <div className={"radioBtns"}>
                            <input id={"currencyMXN"} name={"currency"} type="radio"
                                   checked={this.state.currency === "MXN"} value={"MXN"}
                                   onChange={this.handleInputChange}/>
                            <label htmlFor={"currencyMXN"}>MXN</label>
                            <input id={"currencyUSD"} name={"currency"} type="radio"
                                   checked={this.state.currency === "USD"} value={"USD"}
                                   onChange={this.handleInputChange}/>
                            <label htmlFor={"currencyUSD"}>USD</label>
                        </div>
                    </div>
                    <input type={"number"} value={this.state.amount} name={"amount"} placeholder={"Monto"}
                           onChange={this.handleInputChange}/>
                    <div className={"bankInfo"}>
                        <p>Información bancaria:</p>
                        <input type={"text"} value={this.state.bankName} name={"bankName"} placeholder={"Banco"}
                               onChange={this.handleInputChange}/>
                        <input type={"text"} value={this.state.bankAccountOwner} name={"bankAccountOwner"}
                               placeholder={"Nombre de beneficiario"} onChange={this.handleInputChange}/>
                        <input type={"text"} value={this.state.bankAccountNumber} name={"bankAccountNumber"}
                               placeholder={"Número de cuenta"} onChange={this.handleInputChange}/>
                    </div>
                    <div className={"radioOptionsInput"}>
                        <p>Urgente:</p>
                        <div className={"radioBtns"}>
                            <input id={"importantNo"} name={"important"} type="radio"
                                   checked={this.state.important === "0"} value={"0"}
                                   onChange={this.handleInputChange}/>
                            <label htmlFor={"importantNo"}>No</label>
                            <input id={"importantYes"} name={"important"} type="radio"
                                   checked={this.state.important === "1"} value={"1"}
                                   onChange={this.handleInputChange}/>
                            <label htmlFor={"importantYes"}>Sí</label>
                        </div>
                    </div>
                    <div className={"fileAttachmentWrp"}>
                        <p>Documentos:</p>
                        <div className={"attachedFilesWrp"}>
                            <ul>
                                {attachedFiles}
                            </ul>
                        </div>
                        <label htmlFor={"fileInput"} className={"attachFileBtn"}><span>Adjuntar documento</span></label>
                        <input id={"fileInput"} type={"file"} onChange={this.handleFileAttachment}/>
                    </div>
                    <div className={"formBtnsWrp"}>
                        <NavLink className={"button"} to="/">Cancelar</NavLink>
                        <button className={"button"} type={"submit"}>Crear solicitud</button>
                    </div>
                </form>
            </div>
        </div>
    }
}

export default CreateTicketPage;