import React from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import TicketListPage from './components/views/TicketListPage';
import CreateTicketPage from './components/views/CreateTicketPage';
import EditTicketPage from './components/views/EditTicketPage';
import LoginPage from './components/views/LoginPage';
import {initializeApp} from "firebase/app";
import {onValue, getDatabase, ref, child, get, set, update} from "firebase/database";
import {onAuthStateChanged, getAuth} from "firebase/auth";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { getStorage } from "firebase/storage";
import './App.scss';
import RegisterPage from "./components/views/RegisterPage";
import PasswordRecovery from "./components/views/PasswordRecovery";
import NotificationsPage from "./components/views/NotificationsPage";
import UsersPage from "./components/views/UsersPage";
import {NotificationContainer, NotificationManager} from 'react-notifications';

const baseURL = document.querySelector('base')?.getAttribute('href') ?? '/';

class App extends React.Component {

    constructor(props){
        super(props);

        const firebaseConfig = {
            apiKey: "AIzaSyBTjDT7up8unuAn2avsaJePtTrJFSYk0eI",
            authDomain: "solicitud-pagos.firebaseapp.com",
            projectId: "solicitud-pagos",
            storageBucket: "solicitud-pagos.appspot.com",
            messagingSenderId: "350092361401",
            appId: "1:350092361401:web:3b4c14bce23c3eec526568",
        };
        this.firebaseApp = initializeApp(firebaseConfig);
        this.storage = getStorage(this.firebaseApp);
        this.firebaseAuth = getAuth(this.firebaseApp);
        this.messaging = getMessaging(this.firebaseApp);

        this.requestNotificationsBrowserPermission = this.requestNotificationsBrowserPermission.bind(this);
        this.getFCMToken = this.getFCMToken.bind(this);

        this.state = {
            user:null,
            loading:false,
            ticketsData:[],
            notificationsPermissionStatus: (window.Notification)? Notification.permission : "unsupported"
        };
    }

    componentDidMount() {
        this.startFirebaseConnection();

        onAuthStateChanged(this.firebaseAuth, firebaseUser => {

            //console.log("onAuthStateChanged", firebaseUser);

            if (firebaseUser != null) {

                //console.log(firebaseUser);

                this.getAppUser(firebaseUser)
                    .then((appUser)=>{

                        //console.log("appuser", appUser);

                        this.setState({user:appUser});
                        this.startOnMessageListener();

                        if(window.Notification && Notification.permission === "granted"){
                            deleteToken(this.messaging).then(data=>{
                                //console.log("FCM token deleted", data);
                                this.getFCMToken();
                            });

                        }
                    })
                    .catch(error=>{
                        console.log(error);
                        alert("Hubo un problema con la información del usuario, contacta al administrador.");
                    });
            }else{
                this.setState({user:null});
            }
        });
    }

    requestNotificationsBrowserPermission(){
        try {
            Notification.requestPermission()
                .then((permission) => {
                    this.setState({ notificationsPermissionStatus:permission});
                    this.getFCMToken();
                })
                .catch((error)=>{
                    console.log(error);
                });
        } catch(e) {
            Notification.requestPermission((permission)=>{
                this.setState({ notificationsPermissionStatus:permission});
                this.getFCMToken();
            });
        }
    }

    getFCMToken(){
        getToken(this.messaging, { vapidKey: 'BGnfJuGasCUlrdChIzh2C0UeG1QYsnac-oqgxOlh3srrK3OU8XYGV2vfHtGVAYBSx3Q88UV2QJkL2Hhx_dqG3cs' })
            .then((currentToken) => {
                if (currentToken) {
                    //console.log("Save token:" + currentToken);
                    return update(ref(getDatabase(), "users/" + this.state.user.id), {"fcmToken":currentToken});
                } else {
                    //console.log("No current token");
                }
            })
            .catch((err) => {
                console.log('An error occurred while retrieving or saving user FCM token.', err);
            });
    }

    startOnMessageListener(){
        onMessage(this.messaging, (payload) => {
            NotificationManager.info(payload.notification.body, payload.notification.title, 5000, () => {
                window.location.href = baseURL + "/#";
            });
        });
    }

    startFirebaseConnection(){
        const dbRef = ref(getDatabase(), "tickets/");
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            this.setState({ticketsData:data});
        });
    }

    getUserInvitation(firebaseUser){
        return get(child(ref(getDatabase()), "userInvitations/"))
            .then( snapshot => {
                if(snapshot.exists() && snapshot.val()["admins"].indexOf(firebaseUser.email)>-1){
                    const name = snapshot.val()["names"][firebaseUser.email.replace(/\./g, "").replace("@", "")];
                    return Promise.resolve({ type:"admin", firebaseUser:firebaseUser, name:name });
                }else if(snapshot.exists() && snapshot.val()["collabs"].indexOf(firebaseUser.email)>-1){
                    const name = snapshot.val()["names"][firebaseUser.email.replace(/\./g, "").replace("@", "")];
                    return Promise.resolve({ type:"collab", firebaseUser:firebaseUser, name:name });
                }else{
                    return Promise.reject();
                }
            });
    }

    registerNewUserAs(invitationData){
        const appUserData = { userType:invitationData.type, id:invitationData.firebaseUser.uid, name:invitationData.name };
        return set(ref(getDatabase(), "users/" + invitationData.firebaseUser.uid), appUserData)
            .then(_=>{
                return Promise.resolve(appUserData);
            });
    }

    getAppUser(firebaseUser){
        return get(child(ref(getDatabase()), "users/" + firebaseUser.uid))
            .then(snapshot=>{
                if(snapshot.exists()){
                    return Promise.resolve(snapshot.val());
                } else{
                    return this.getUserInvitation(firebaseUser)
                        .then(this.registerNewUserAs)
                }
            });
    }

    render() {
        if(this.state.loading){
            return (
                <div className={"mainWrapper mainWrapper-loading"}>
                    <div className={"preloader"} />
                </div>
            )
        } else if (this.state.user != null) {
            return (
                <div className={"mainWrapper mainWrapper-signedIn"}>
                    <NotificationContainer/>
                    {(this.state.notificationsPermissionStatus === "unsupported")?
                        <div className={"notificationsMessageWrp"}>
                            <p>Tu navegador no permite el envío de notificaciones.</p>
                        </div>
                        : (this.state.notificationsPermissionStatus === "denied")?
                            <div className={"notificationsMessageWrp"}>
                                <p>Las notificaciones están deshabilitadas. Habilita las notificaciones en tu navegador y vuelve a iniciar sesión para habilitarlas.</p>
                            </div>
                            : (this.state.notificationsPermissionStatus === "default"?
                                <div className={"notificationsMessageWrp"}>
                                    <p>Debes permitir el envío de notificaciones para poder recibir notificaciones relacionadas con cambios en las solicitudes.</p>
                                    <button className={"button"} onClick={this.requestNotificationsBrowserPermission}>Activar notificaciones</button>
                                </div>
                                : "")}
                    <HashRouter>
                        <Routes>
                            <Route path="/users" element={ <UsersPage user={this.state.user} firebaseAuth={this.firebaseAuth} /> }/>
                            <Route path="/create" element={ <CreateTicketPage user={this.state.user} firebaseAuth={this.firebaseAuth} /> }/>
                            <Route path="/notifications" element={ <NotificationsPage user={this.state.user} firebaseAuth={this.firebaseAuth} /> }/>
                            <Route path="/edit/:ticketId" element={ <EditTicketPage user={this.state.user} firebaseAuth={this.firebaseAuth} /> } />
                            <Route path="*" element={ <TicketListPage tickets={this.state.ticketsData} user={this.state.user} firebaseAuth={this.firebaseAuth} /> }/>
                        </Routes>
                    </HashRouter>
                </div>
            );
        } else{
            return (
                <div className={"mainWrapper mainWrapper-notSignedIn"}>
                    <HashRouter>
                        <Routes>
                            <Route path="/register" element={<RegisterPage firebaseAuth={this.firebaseAuth}/>}/>
                            <Route path="/passwordRecovery" element={<PasswordRecovery firebaseAuth={this.firebaseAuth}/>}/>
                            <Route path="*" element={ <LoginPage firebaseAuth={this.firebaseAuth}/>}/>
                        </Routes>
                    </HashRouter>
                </div>
            );
        }
    }
}

export default App;